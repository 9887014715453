import React from 'react';
import { Menu } from 'antd';
import { Link, Route, withRouter } from 'react-router-dom';
import IconFont from 'components/IconFont';
import { connect } from 'react-redux';

const { locale, getNoAuthPath } = window.common;

class LeftMenu extends React.Component {
  constructor(props) {
    super(props);
    this.pathName = this.props.location.pathname;
    this.obsFlag = window.user.obsFlag;
    this.farm = this.props.farm;
    this.state = {
      menus: this.props.menus || [],
      openKeys: this.getOpenKeys(this.props.menus || [])
    };
  }

  getOpenKeys(menuList = [], crumb = []) {
    for (let i = 0; i < menuList.length; i += 1) {
      const menu = menuList[i];
      if (menu.path === this.pathName) {
        crumb.unshift(menu);
        break;
      } else if (crumb.length === 0 && menu.children) {
        this.getOpenKeys(menu.children, crumb);
      }
      if (crumb.length > 0) {
        crumb.unshift(menu);
        break;
      }
    }
    return crumb.length > 0 ? crumb.map(a => a.menuName) : [];
  }

  hidePath = (cell) => {
    const noAuthPath = getNoAuthPath(this.props.farm, this.obsFlag);
    return !noAuthPath.includes(cell.path || cell.menuName);
  }

  onOpenChange = (openKeys) => {
    const level1 = this.state.menus.map(a => a.menuName);
    const level1OpenKeys = openKeys.filter(a => level1.includes(a));
    let level2OpenKeys = openKeys.filter(a => !level1.includes(a));

    if (level1OpenKeys.length > 1) {
      level2OpenKeys = [];
    }
    this.setState({
      openKeys: [level1OpenKeys.pop(), level2OpenKeys.pop()],
    });
  };

  getMenuItemPath = (menu, hasIcon) => (
    <Menu.Item key={menu.path}>
      <Link
        to={menu.path}
        // onClick={() => { window.sendEvent('menu_click', { menu_name: menu.menuName }); }}
        title={locale(menu.menuName)}>
        <span style={{ display: 'flex', alignItems: 'center' }}>{hasIcon && <IconFont style={{ fontSize: '20px' }} type={menu.icon} />}<span>{locale(menu.menuName)}</span></span>
      </Link>
    </Menu.Item>
  );

  getMenuItems(item, hasIcon) {
    if (item.children && item.children.length > 0) {
      return (
        <Menu.SubMenu
          key={item.menuName}
          title={(
            <span>
              {hasIcon && <IconFont style={{ fontSize: '20px' }} type={item.icon} />}
              <span title={locale(item.menuName)}>{locale(item.menuName)}</span>
            </span>)}
        >
          {item.children.filter(this.hidePath).map((cell) => {
            if (cell.children && cell.children.length > 0) {
              return this.getMenuItems(cell);
            }
            return this.getMenuItemPath(cell);
          })}
        </Menu.SubMenu>
      );
    }
    return this.getMenuItemPath(item, true);
  }

  render() {
    const { menus, openKeys } = this.state;
    const { pathname } = this.props.location;
    return (
      <Route children={() => (
        menus.length > 0 ? (
          <Menu
            theme="dark"
            mode="inline"
            inlineIndent={30}
            style={{ width: 270 }}
            selectedKeys={[pathname]}
            openKeys={openKeys}
            onOpenChange={this.onOpenChange}>
            {menus.filter(this.hidePath).map(item => this.getMenuItems(item, true))}
          </Menu>
        ) : null
      )}
      />
    );
  }
}

const mapStateToProps = state => ({
  farm: state.farm,
  menus: state.menu.menus,
});

export default withRouter(
  connect(mapStateToProps)(LeftMenu)
);

import './PageHeader.less';
import React from 'react';
import {
  Layout, Menu, Dropdown, Switch, Select
} from 'antd';
import { connect } from 'react-redux';
import SystemAlert from '../components/SystemAlert';

const {
  locale, api, util, docLinks, websiteLinks
} = window.common;
const lngs = [
  { value: 'en', name: 'English', },
  { value: 'zhtw', name: '繁體中文', }
];
class PageHeader extends React.Component {
  constructor(props) {
    super(props);
    this.paramsLng = this.props?.params?.lng;
    this.hideSystemAlert = this.props.hideSystemAlert;
    this.user = window.user || {};
  }

  componentDidMount() {
    //处理原来的简体中文
    if (window.Bitmain.lng === 'zh') {
      this.changeLanguage('zhtw');
    }
    // 官网跳转过来的观察者，参数有lng，保持和官网语言一致
    if (this.paramsLng) {
      if (!lngs.map(a => a.value).includes(this.paramsLng)) {
        this.paramsLng = 'en';
      }
      window.Bitmain.lng !== this.paramsLng && this.changeLanguage(this.paramsLng);
    }
  }

  changeLanguage = (value) => {
    api({
      url: '/language/update',
      params: {
        lng: value,
      },
    }, { apiServer: '/demo' }).then(() => {
      window.location.reload();
    });
  };

  handleClick = (item) => {
    if (item.key === 'logout') {
      window.user = null;
      localStorage.setItem('bitmain', JSON.stringify(window.Bitmain));
      window.location.href = '/user/logout';
    } else if (item.key === 'user') {
      window.open(websiteLinks.account);
    }
  };


  render() {
    const menu = (
      <Menu onClick={this.handleClick}>
        <Menu.Item style={{ lineHeight: '22px' }} key="user"> {locale('用户中心')}</Menu.Item>
        <Menu.Divider />
        <Menu.Item style={{ lineHeight: '22px' }} key="logout">{locale('退出')}</Menu.Item>
      </Menu>
    );
    return (
      <Layout.Header className="bm-header">
        {!this.hideSystemAlert && <SystemAlert />}
        <ul className="header-right">
          {/*测试环境和有本地存储标识的时候显示切换版本按钮 */}
          {(window.location.hostname.includes('deeppos') || localStorage.getItem('API-VERSION'))
            && (
              <li>
                <Switch
                  defaultChecked={localStorage.getItem('API-VERSION') === 'flink'}
                  onChange={(checked) => {
                    localStorage.setItem('API-VERSION', checked ? 'flink' : '1');
                  }} />
              </li>
            )}
          <li><a href={docLinks.help[window.Bitmain.lng]} target="_blank" rel="noopener noreferrer">{locale('帮助中心')}</a></li>
          {!this.user.obsFlag && (
            <li>
              <Dropdown overlay={menu}>
                <span className="user-info">
                  <span title={this.user.email || this.user.phone}>{this.user.email || this.user.phone}</span>
                </span>
              </Dropdown>
            </li>
          )}
          <li>
            <Select onChange={this.changeLanguage} style={{ width: '90px' }} size="small" defaultValue={window.Bitmain.lng}>
              {lngs.map(item => <Select.Option value={item.value}>{item.name}</Select.Option>)}
            </Select>
          </li>
        </ul>
      </Layout.Header>
    );
  }
}


const mapStateToProps = (state) => {
  const tempLocation = state.routing.location || {};
  return {
    pathname: tempLocation.pathname,
    params: util.queryToJson(tempLocation.search || ''),
  };
};

export default connect(mapStateToProps)(PageHeader);

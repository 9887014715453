import React, { Component } from 'react';
import MessageView from '../../../../pages/Message/MessageView';

const { api, util } = window.common;
let currentIndex = 0;

export default class ShowImportant extends Component {
  constructor(props) {
    super(props);
    this.importantList = this.props.importantList || [];
    this.state = { visibleView: true };
  }

  onClose = () => {
    this.closeInfo();
    currentIndex++;
    if (currentIndex <= (this.importantList.length - 1)) {
      this.setState({ visibleView: false }, () => { this.setState({ visibleView: true }); });
    } else {
      currentIndex = 0;
      this.props.onClose();
    }
  }

  closeInfo = () => {
    api({
      method: 'post',
      url: '/al/information/click',
      data: {
        infoId: this.importantList[currentIndex].id,
        zone: util.timezone.tz.guess()
      },
    }).then(() => {});
  }

  render() {
    return (
      <div>
        {this.state.visibleView && (<MessageView detail={this.importantList[currentIndex]} onClose={this.onClose} />)}
      </div>
    );
  }
}

import './Download.less';
import React from 'react';
import { locale, api, util } from 'common';

export default class Download extends React.Component {
  constructor(props) {
    super(props);
    const { search } = this.props.location;
    this.taskId = util.queryToJson(search).taskId || '';
    this.state = { fileInfo: {} };
  }

  componentDidMount() {
    api({
      method: 'get',
      loading: true,
      url: '/job/file/valid',
      params: {
        taskId: this.taskId
      }
    }, { apiServer: '/log' }).then((res) => {
      this.setState({ ...res.data });
    });
  }

  download = () => {
    window.open(this.state.minerLogUrl + (this.state.fileInfo && this.state.fileInfo.downloadUrl));
  }

  render() {
    const {
      fileInfo, createAt, valid, validTime
    } = this.state;
    return (
      <div className="download-con">
        <div className="text-item">
          <span className="item-label">{locale('文件名称')}</span> ：<span className="item-content" >{fileInfo && fileInfo.fileName}</span>
        </div>
        <div className="text-item">
          <span className="item-label">{locale('创建时间')}</span> ：<span className="item-content" >{util.timestampToTime(createAt)}</span>
        </div>
        <div className="text-item">
          <span className="item-label">{locale('失效时间')}</span> ：<span className="item-content" >{valid === 'Y' ? util.timestampToTime(validTime) : locale('文件已过期')}</span>
        </div>
        <div className="text-item">
          {
            valid === 'Y'
              ? <a onClick={this.download}>{locale('下载')}</a>
              : <a style={{ backgroundColor: '#b1b1b1', borderColor: ' #b1b1b1' }}>{locale('下载')}</a>
          }
        </div>
      </div>
    );
  }
}

import './PageSider.less';
import React from 'react';
import { Layout } from 'antd';
import IconFont from 'components/IconFont';
import LeftMenu from '../components/LeftMenu';
import logo from '../../../assets/images/logo.png';
import qcode from '../../../assets/images/sider-footer-qcode.png';
import google from '../../../assets/images/sider-footer-google.png';
import apple from '../../../assets/images/sider-footer-store.png';

const { Sider } = Layout;
const { locale, websiteLinks } = window.common;
export default class Logo extends React.Component {
  constructor(props) {
    super(props);
    this.user = window.user || {};
    this.showTenantName = this.user.obsFlag && !!this.user.tenantName;
  }

  render() {
    return (
      <Sider width="270px" className="bm-sider">
        <div className="bm-logo-con">
          <img className="bm-logo" src={logo} alt="" />
          <div className="obs-con">
            {this.user.obsFlag && (
            <div className="watcher">
              <IconFont type="iconguanchazhemoshi" />
              <span>{locale('观察者模式')} </span>
            </div>
            )}
            {!!this.user.tenantName && (
            <div className="tenant-name">
              <IconFont style={{ fontSize: '25px' }} type="iconkehu" />
              <span title={this.user.tenantName} className="text-cut">{this.user.tenantName}</span>
            </div>
            )}
          </div>
        </div>
        <div className={`bm-sider-menus ${this.showTenantName ? 'obs-menus' : ''}`}>
          <LeftMenu />
        </div>
        <div className="sider-footer">
          <div className="sider-footer-item">
            <a href={websiteLinks.minerplus} target="_blank" rel="noopener noreferrer">
              <IconFont type="iconguanwang" />
              <span>{locale('官网')}</span>
            </a>
          </div>
          <div className="sider-footer-item">
            <IconFont type="iconshouji" />
            <span>APP</span>
            <div className="app-download">
              <div className="app-store">
                <a href={`https://apps.apple.com/sg/app/mining/id1569291956?l=${window.lng}`} target="_blank" rel="noopener noreferrer"><img src={apple} alt="" /></a>
                <a href="https://play.google.com/store/apps/details?id=com.antsentry.app.android" target="_blank" rel="noopener noreferrer"><img src={google} alt="" /></a>
              </div>
              <div className="app-qcode">
                <img src={qcode} alt="" />
              </div>
            </div>
          </div>

        </div>
      </Sider>
    );
  }
}
